<template>
  <div class="welcome-page">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="'Welcome Page Overview'"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          v-if="isSwitch && isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          type="warning"
          @click="handleEnableClick(scope.row, 'disable')"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="isSwitch && !isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row, 'enable')"
          >{{ $t('general.enable') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'
import { searchFormConfig } from './config/welcome.form'
import { contentTableConfig } from './config/welcome.table'
import { useRouter } from 'vue-router'
import { usePermission } from '@/hooks/use-permission'
import { cloneDeep } from 'lodash'

const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

// 表格基础配置项
const tableOptions = {
  moduleName: 'file', // 所属的模块
  pageName: 'apphome', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  pageAction: 'file/getWelcomePage', // 获取表格的Action
  deleteRecordAction: 'file/deleteWelcomeByID', // 删除record的Action
  pageCountGetter: 'file/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'file/pageListData' // 获取表格列表数据getters
}

const tableOptionsConfig = computed(() => tableOptions)

// // 获取页面操作的权限
const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)

const pageContentRef = ref('')
const pageSearchRef = ref('')

const isNormalStatus = (status) => {
  return status === '00'
}

const formateData = (data) => {
  const formateObject = cloneDeep(data)
  formateObject.tenantId = data.tenantId ? data.tenantId.join(',') : ''
  return formateObject
}

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(formateData(queryInfo))
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()

const handleNewData = () => {
  router.push(`/file/apphome/new`)
}

const handleViewData = (id) => {
  router.push(`/file/apphome/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/file/apphome/edit/${id}`)
}
const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formateData(formData))
}

const handleEnableClick = async (row, type) => {
  const url =
    type === 'enable'
      ? 'handleEnableWelcomePageAction'
      : 'handleDisableWelcomePageAction'
  handleMessageCommit(`file/${url}`, row.id).then(() => {
    pageContentRef.value.getPageData()
  })
}
</script>
