import {
  getCommonParamsOptions,
  getParamsOptionsAsync
} from '@/utils/common.js'

export const searchFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.picture-name'
    },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'user.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        props: {
          multiple: true
        }
      },
      options: [],
      handler: () =>
        getParamsOptionsAsync('tenantList', {
          isNeedPreFix: false
        })
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      options: [],
      isResolveGlobalParams: true,
      handler: () => getCommonParamsOptions('status')
    },
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange',
        format: 'YYYY-MM-DD',
        valueFormat: 'YYYY-MM-DD'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
